/* MAIN HEADER
========================================================================== */

.locBlurb {
    color: $colorBrandSecondary;
    list-style: none;
    margin-bottom: 0;
    padding-right: $spacingBase;
    list-style: none;
    @include mq(med) {
        text-align: right;
        padding-right: $spacingSm;
    }
    li {
        display: block;
        list-style: none;
        &.franchise {
            font-weight: bold;
        }
        .contact-phone {
            display: flex;
            @include justify-content(space-between);
            a {
                text-decoration: underline;
                text-decoration-color: $colorBrandSecondary;
                color: $colorBrandSecondary;
                &:not(:first-of-type):not(:last-of-type) {
                    margin-inline: $spacingBase;
                }
            }
            .telephone {
                @extend .icon-phone;
                cursor: pointer;
                pointer-events: auto;
                &:before {
                    text-decoration: none;
                    @extend %iconStyle;
                }
            }
        }
    }
    > li:first-child:before {
        display: none;
    }
}